import AdmissionRequestFiles from "./AdmissionRequestFiles";
import AwardDetail from "./AwardDetail";
import BasicTemplate from "./BasicTemplate";
import BlogDetail from "./BlogDetail";
import BlogList from "./BlogList";
import Error404 from "./Error404";
import EventDetail from "./EventDetail";
import EventHeroForm from "./EventHeroForm";
import EventList from "./EventList";
import JobOfferDetail from "./JobOfferDetail";
import JobOfferList from "./JobOfferList";
import LandingEasyPackPortugal from "./LandingEasyPackPortugal";
import LandingEventPortugal from "./LandingEventPortugal";
import LandingInfoPortugal from "./LandingInfoPortugal";
import LandingTMGEventPortugal from "./LandingTMGEventPortugal";
import LegalTemplate from "./LegalTemplate";
import Modal from "./Modal";
import MultiproductHeroForm from "./MultiproductHeroForm";
import NewsDetail from "./NewsDetail";
import NewsList from "./NewsList";
import ProductHeroForm from "./ProductHeroForm";
import Quizz from "./Quizz";
import StaffDetail from "./StaffDetail";
import StaffList from "./StaffList";
import StudyDetail from "./StudyDetail";
import StudyList from "./StudyList";

export default {
	AdmissionRequestFiles,
	AwardDetail,
	BasicTemplate,
	BlogDetail,
	BlogList,
	Error404,
	EventDetail,
	EventHeroForm,
	EventList,
	JobOfferDetail,
	JobOfferList,
	LandingEasyPackPortugal,
	LandingEventPortugal,
	LandingInfoPortugal,
	LandingTMGEventPortugal,
	LegalTemplate,
	Modal,
	MultiproductHeroForm,
	NewsDetail,
	NewsList,
	ProductHeroForm,
	Quizz,
	StaffDetail,
	StaffList,
	StudyDetail,
	StudyList,
};
